import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import YoutubeVideo from "components/Internet_Access/Port_Forwarding/AVM_Fritzbox/YoutubeVideoCardForwarding";
import SettingsTable from 'components/Internet_Access/Port_Forwarding/AVM_Fritzbox/SettingsTable';
import NavButtons from 'components/Internet_Access/Port_Forwarding/NavButtons';
import ForumBox from 'components/Internet_Access/Port_Forwarding/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Port Forwarding - AVM Fritzbox",
  "path": "/Internet_Access/Port_Forwarding/AVM_Fritzbox/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Connect to your Camera through the Internet",
  "image": "./IA_SearchThumb_Portforwarding_Fritzbox.png",
  "social": "/images/Search/IA_SearchThumb_Portforwarding_Fritzbox.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-Port-Forwarding_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Port Forwarding - AVM Fritzbox' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Connect to your Camera through the Internet' image='/images/Search/IA_SearchThumb_Portforwarding_Fritzbox.png' twitter='/images/Search/IA_SearchThumb_Portforwarding_Fritzbox.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Port_Weiterleitung/AVM_Fritzbox/' locationFR='/fr/Internet_Access/Port_Forwarding/AVM_Fritzbox/' crumbLabel="AVM Fritzbox" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "port-forwarding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#port-forwarding",
        "aria-label": "port forwarding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Port Forwarding`}</h1>
    <h2 {...{
      "id": "avm-fritzbox",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#avm-fritzbox",
        "aria-label": "avm fritzbox permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`AVM Fritzbox`}</h2>
    <p>{`This is the 2nd step of the Port Forwarding, click `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`here`}</a>{` to get to Step I.`}</p>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <h2 {...{
      "id": "fritzbox-7490--7390",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#fritzbox-7490--7390",
        "aria-label": "fritzbox 7490  7390 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fritzbox 7490 & 7390`}</h2>
    <p>{`Open the WebUI of your FritzBox Router by typing `}<em parentName="p">{`fritz.box`}</em>{` in your browser, or by alternatively typing its IP-address (by default: 192.168.178.1). Log-in and you will find yourself in the main menu of your FritzBox. In the menu, head to `}<strong parentName="p">{`Internet / Freigaben`}</strong>{` and click on `}<em parentName="p">{`Gerät für Freigaben hinzufügen`}</em>{`. (In older WebUI it might be called `}<em parentName="p">{`Neue Portfreigabe`}</em>{`)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5b6a662f30261cb5a9060d375b3e3a00/5d942/Portforwarding-Fritzbox-7490de_new_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACPElEQVQoz1WQvWsUQRyG998QTGG0sLC11cL/wDOtRSorLQQj0VoQ7USjVkLSCDai2IoS8SsnxCQXIRLj5T5293ZvZ2d2Zm53b++RnZMzDjzs8Ht33nnf8U4vvuDu1QucufyUY9c+c/7+Nufu7XD2zhYnlpscv7HB3NL/HF/a4NTtb5y8NWV+ucnczSbzS1/w3nw94OPmJu+3Ojx5e8Dis02urH6nsdKi8ahFY2XXcbHm8Q8W1josrHW5tFoz3dezxlqP6y87eIVVFEUJ/X2wApkMSCKfNA6QcUAahzNEFJAOw5kunB64uVUCxhavyHNSkUBVYYyi1z9kOAwZRD5B2HNfP+gg0sgRBF063TZRHLh/f/3eJxEDojhkmER4D9YjHq6HPH/V5NPeAGsURVlirUGpjMxK+kGfNJXkeU6WKay1VNWY0chOyXOM1hgt8bqxojNISXRJmmVIKTBGY6xG1YdHGiEFeT6iLHOn1SZlWaCNRusMO7LuIp2lePVgXOTUKx9p0lQ4o/qglBKbG0SaYIxxJkcN3cVG/zXMpobCVSmc4cgahBBOrCvXhibP6AV9pFRUVYXWmrzImdQBimJKWbrKtq48iCISkVCNS6zJmEwqJpPJjPF47AxqM8cExpki3t0haLXob+8w/LlHIjJkpvAGQZvQbxMPDhFJ6BLUCVX9nkqhlPo3U4qs3suUoe8T+z4mDsFIrDYukCeGPdJhjyTuoGTkqh9NeJSZxnQVE/jQHvFu3/B6W7B1mPAHG3ocAhravYcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b6a662f30261cb5a9060d375b3e3a00/e4706/Portforwarding-Fritzbox-7490de_new_01.avif 230w", "/en/static/5b6a662f30261cb5a9060d375b3e3a00/d1af7/Portforwarding-Fritzbox-7490de_new_01.avif 460w", "/en/static/5b6a662f30261cb5a9060d375b3e3a00/7f308/Portforwarding-Fritzbox-7490de_new_01.avif 920w", "/en/static/5b6a662f30261cb5a9060d375b3e3a00/4a00a/Portforwarding-Fritzbox-7490de_new_01.avif 1343w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5b6a662f30261cb5a9060d375b3e3a00/a0b58/Portforwarding-Fritzbox-7490de_new_01.webp 230w", "/en/static/5b6a662f30261cb5a9060d375b3e3a00/bc10c/Portforwarding-Fritzbox-7490de_new_01.webp 460w", "/en/static/5b6a662f30261cb5a9060d375b3e3a00/966d8/Portforwarding-Fritzbox-7490de_new_01.webp 920w", "/en/static/5b6a662f30261cb5a9060d375b3e3a00/9c8f3/Portforwarding-Fritzbox-7490de_new_01.webp 1343w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b6a662f30261cb5a9060d375b3e3a00/81c8e/Portforwarding-Fritzbox-7490de_new_01.png 230w", "/en/static/5b6a662f30261cb5a9060d375b3e3a00/08a84/Portforwarding-Fritzbox-7490de_new_01.png 460w", "/en/static/5b6a662f30261cb5a9060d375b3e3a00/c0255/Portforwarding-Fritzbox-7490de_new_01.png 920w", "/en/static/5b6a662f30261cb5a9060d375b3e3a00/5d942/Portforwarding-Fritzbox-7490de_new_01.png 1343w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5b6a662f30261cb5a9060d375b3e3a00/c0255/Portforwarding-Fritzbox-7490de_new_01.png",
              "alt": "Portforwarding AVM Fritzbox",
              "title": "Portforwarding AVM Fritzbox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In this new window under `}<em parentName="p">{`Gerät`}</em>{` choose your camera. Check if the IP-address is correct and then click on `}<em parentName="p">{`Neue Freigabe`}</em>{` in the bottom right.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b68419d4056a7380db36339f42574db4/5d942/Portforwarding-Fritzbox-7490de_new_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB9ElEQVQoz4WSTWsTURSG5zdYEQWluPMHuHTnwv/gyn0rEvQPCBYpLeJWcWVBLIIu3GulIUGwtRurYpVokk4yd+Z+TGYyM3cyeWQmTdpaxQMP99wP3nPfe49z6cYz7tWuMX/9KWcWG1xd/siVpW0u393i3K0Gc4t1Tv/B3EKdi3eaXKg1mL/d5HytwamFOmdvbuIsr2/x8tUa959/YOn1Dx6/1zxqKlY3fFbeimpc3RCT/F0wY6Xc3wgO8HmwKXn4xsXJkgH5mINIoUiYxa/P8HMXvBYIl/+HxcEmpAMJjBnlKSJQiEASDRN6bo9Ou4NWmizLyYsxdvQ3CvIChkmCs76jeLEjWds2fOpohlGI0oYsy7A2x+Y5mbUURTFjPB6foLpfluA0vmvq3yTNVkxXRoRGs+/2kFIhlUIIUeVRFFeUhco4FOO4oE0iGE0O5WmMMYY8z08wFTh6y6nwZA5ZKSgre7bajKIQz+sSBH2E7xIaH9930VpUoyf2qzwMfYRw6fc7KOVhjI82PmEY4PiBJI7jSjBJ4qpKSZoOsTYlTSdzYzRKSbRWs3NJOiTLUmw6rNwlkcHp99p4ZSXZYxDKY00w7abSTlFMrI1Gh3ZnzeZ28HZ30XtfcLTsUiL9NoPQP/ZO//rZo2syEOy1ujxpCOpfBb8B+twmhLcWE2QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b68419d4056a7380db36339f42574db4/e4706/Portforwarding-Fritzbox-7490de_new_02.avif 230w", "/en/static/b68419d4056a7380db36339f42574db4/d1af7/Portforwarding-Fritzbox-7490de_new_02.avif 460w", "/en/static/b68419d4056a7380db36339f42574db4/7f308/Portforwarding-Fritzbox-7490de_new_02.avif 920w", "/en/static/b68419d4056a7380db36339f42574db4/4a00a/Portforwarding-Fritzbox-7490de_new_02.avif 1343w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b68419d4056a7380db36339f42574db4/a0b58/Portforwarding-Fritzbox-7490de_new_02.webp 230w", "/en/static/b68419d4056a7380db36339f42574db4/bc10c/Portforwarding-Fritzbox-7490de_new_02.webp 460w", "/en/static/b68419d4056a7380db36339f42574db4/966d8/Portforwarding-Fritzbox-7490de_new_02.webp 920w", "/en/static/b68419d4056a7380db36339f42574db4/9c8f3/Portforwarding-Fritzbox-7490de_new_02.webp 1343w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b68419d4056a7380db36339f42574db4/81c8e/Portforwarding-Fritzbox-7490de_new_02.png 230w", "/en/static/b68419d4056a7380db36339f42574db4/08a84/Portforwarding-Fritzbox-7490de_new_02.png 460w", "/en/static/b68419d4056a7380db36339f42574db4/c0255/Portforwarding-Fritzbox-7490de_new_02.png 920w", "/en/static/b68419d4056a7380db36339f42574db4/5d942/Portforwarding-Fritzbox-7490de_new_02.png 1343w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b68419d4056a7380db36339f42574db4/c0255/Portforwarding-Fritzbox-7490de_new_02.png",
              "alt": "Portforwarding AVM Fritzbox",
              "title": "Portforwarding AVM Fritzbox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you have to apply the settings as follows:`}</p>
    <SettingsTable mdxType="SettingsTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "712px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/36e6310ca055f49a8cfc3256d151db8e/3d4b6/Portforwarding-Fritzbox-7490de_new_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABGUlEQVQoz42SWWvDMBCE/f9/W+lT6RViRdYZSbu6bcvOQ2lMj7QkLczDsGh24Ft1nNnnp0O/l68vg4cxhfZ/dUQ1ygOj5qhjjksKLfr5S7fDiE1w7PdCCcxxyXFxJlAqOJOE6nvSSmzXVnQlNsEc6ZWS+D6Ki2JaUMaZ1K7U8ZTTfLU5hSYFUqLR1RyXklarEQykNN3tqrUlx3YrrKUfqBmoAVeinzlTpB+U8IofBdcbiKvhA9H0oLX0nFmwxR690VjzKce1pPUWsBzmcL5QuXy60Q44XcC/VEdkI9QzqgdqpMDP2I+SHJZv/qPZuQlcBVcQKkL1OP0WQAUozpWzqZtBGLs/v1H0M2Bh0ghlMVQu7cPjjlDh/fgGotNqbNTgeLcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36e6310ca055f49a8cfc3256d151db8e/e4706/Portforwarding-Fritzbox-7490de_new_03.avif 230w", "/en/static/36e6310ca055f49a8cfc3256d151db8e/d1af7/Portforwarding-Fritzbox-7490de_new_03.avif 460w", "/en/static/36e6310ca055f49a8cfc3256d151db8e/4fb90/Portforwarding-Fritzbox-7490de_new_03.avif 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/36e6310ca055f49a8cfc3256d151db8e/a0b58/Portforwarding-Fritzbox-7490de_new_03.webp 230w", "/en/static/36e6310ca055f49a8cfc3256d151db8e/bc10c/Portforwarding-Fritzbox-7490de_new_03.webp 460w", "/en/static/36e6310ca055f49a8cfc3256d151db8e/c4538/Portforwarding-Fritzbox-7490de_new_03.webp 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36e6310ca055f49a8cfc3256d151db8e/81c8e/Portforwarding-Fritzbox-7490de_new_03.png 230w", "/en/static/36e6310ca055f49a8cfc3256d151db8e/08a84/Portforwarding-Fritzbox-7490de_new_03.png 460w", "/en/static/36e6310ca055f49a8cfc3256d151db8e/3d4b6/Portforwarding-Fritzbox-7490de_new_03.png 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/36e6310ca055f49a8cfc3256d151db8e/3d4b6/Portforwarding-Fritzbox-7490de_new_03.png",
              "alt": "Portforwarding AVM Fritzbox",
              "title": "Portforwarding AVM Fritzbox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`By clicking on `}<strong parentName="p">{`OK`}</strong>{` and then `}<strong parentName="p">{`Übernehmen`}</strong>{`, the settings will be saved and the camera is listed in the Port Forwardings.`}</p>
    <p>{`To be able to access the Flash Video as well, there has to be set a second Port Forwarding. Just follow the manual of this step, with the only difference to set the Flash (RTMP) Port instead. So for your first camera all ports are set to 1935, for your second camera it´s 1936, etc.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "712px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e3869e9aef3613f9a5d3458c4b8d1a13/3d4b6/Portforwarding-Fritzbox-7490de_new_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABT0lEQVQoz4VSyXLDIBTL//9ae+t02qTjBWMwZvUC8ZKDOtDETaYT9yADBvQkPQ60kvj8KJFnHMcjgTNnDN2C3s1p/MX85P8jDkWzgNAOlLRoRb9diujshN5NP+s7sj3Sg7EL6togzxg4s9uGkh0IYWCUIys4XrMZvr8V21E4dgtqqlDkDZqNcEVDBVhZgbEGp5xD6QFhXK/qdhTGD6sNSClSfmO/YuwvkMLAKou+n/DyFWDi3mZ3hzAeENyhIjLBKJ+ya7iEEBq8tuC0hZYuFbvl+wxJYbRLCpEsR/taeqjWwegBfrgkohjDfx2+Zjijuz6VeNkPa7IUg791+qHbbtrBjEPOF+TEghKBqmzBmXl4Oikvd4f/FGp9hlYhZWd0gNUhNecZjAnQOp71aR7HtDYe1oSY4d+qe6Eb61GxFjVXMC6AMom39xPyksG5M74BBRBEylE6ZjUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e3869e9aef3613f9a5d3458c4b8d1a13/e4706/Portforwarding-Fritzbox-7490de_new_04.avif 230w", "/en/static/e3869e9aef3613f9a5d3458c4b8d1a13/d1af7/Portforwarding-Fritzbox-7490de_new_04.avif 460w", "/en/static/e3869e9aef3613f9a5d3458c4b8d1a13/4fb90/Portforwarding-Fritzbox-7490de_new_04.avif 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e3869e9aef3613f9a5d3458c4b8d1a13/a0b58/Portforwarding-Fritzbox-7490de_new_04.webp 230w", "/en/static/e3869e9aef3613f9a5d3458c4b8d1a13/bc10c/Portforwarding-Fritzbox-7490de_new_04.webp 460w", "/en/static/e3869e9aef3613f9a5d3458c4b8d1a13/c4538/Portforwarding-Fritzbox-7490de_new_04.webp 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e3869e9aef3613f9a5d3458c4b8d1a13/81c8e/Portforwarding-Fritzbox-7490de_new_04.png 230w", "/en/static/e3869e9aef3613f9a5d3458c4b8d1a13/08a84/Portforwarding-Fritzbox-7490de_new_04.png 460w", "/en/static/e3869e9aef3613f9a5d3458c4b8d1a13/3d4b6/Portforwarding-Fritzbox-7490de_new_04.png 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e3869e9aef3613f9a5d3458c4b8d1a13/3d4b6/Portforwarding-Fritzbox-7490de_new_04.png",
              "alt": "Portforwarding AVM Fritzbox",
              "title": "Portforwarding AVM Fritzbox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now all Port Forwarding's for your camera are set in the router. You can now continue with the next step:`}</p>
    <h2 {...{
      "id": "step-iii--the-cameras-ddns-address-and-the-remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-iii--the-cameras-ddns-address-and-the-remote-access",
        "aria-label": "step iii  the cameras ddns address and the remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step III : The Camera´s DDNS-Address and the Remote-Access`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Internet_Access/Mobile_Access/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Step III</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "trouble-shooting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#trouble-shooting",
        "aria-label": "trouble shooting permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Trouble Shooting`}</h3>
    <p><strong parentName="p">{`Mobile Access Points`}</strong>{` - 3G / LTE Router which use a SIM-card often don´t get a public IPv4 Address which is needed for the DDNS Service. If you use a mobile internet connection on the network of your camera and you´re not able to access it through the internet, you should ask your provider if it is possible to get a WAN IP. Most of the business contracts usually offer this option. A public IP Address is needed to access the camera by using DDNS -> `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Point_to_Point/"
      }}>{`See P2P`}</a></p>
    <p>{`Another problem cause could be a `}<strong parentName="p">{`DS-Lite`}</strong>{` (Dual Stack Lite) connection. It is not possible to access your camera via DDNS if you are using DS-Lite. This is the case if your provider is using IPv6-addresses instead of IPv4-addresses. You can check `}<a parentName="p" {...{
        "href": "http://www.wieistmeineip.de/"
      }}>{`Here`}</a>{`, if you have an IPv4-address -> `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Point_to_Point/"
      }}>{`See P2P`}</a>{`.`}</p>
    <p>{`If you´re using a `}<strong parentName="p">{`mobile Access Point`}</strong>{` or `}<strong parentName="p">{`IPv6`}</strong>{` with your camera, there is only one solution for the remote access. All of our HD-Cameras have a Point-2-Point UserID (P2P UID), which makes remote access possible no matter which internet connection you use with your camera -> `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Point_to_Point/"
      }}>{`See P2P`}</a>{`.`}</p>
    <p>{`Another reason why you can´t remotely access your camera via DDNS might be the `}<strong parentName="p">{`Kindersicherung`}</strong>{` (child safety device) of your FritzBox. You can check in the list for the child safety for your camera and grant it `}<strong parentName="p">{`unbeschränkt`}</strong>{` (unlimited) access.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      